import React from 'react'

import { Helmet } from 'react-helmet'

import './nijjar-legacy.css'

const NijjarLegacy = (props) => {
  return (
    <div className="nijjar-legacy-container">
      <Helmet>
        <title>Nijjar Legacy</title>
        <meta property="og:title" content="Nijjar Legacy" />
      </Helmet>
      <div data-role="Header" className="nijjar-legacy-navbar-container">
        <div className="nijjar-legacy-navbar">
          <div className="nijjar-legacy-left-side">
            <div data-role="BurgerMenu" className="nijjar-legacy-burger-menu">
              <svg viewBox="0 0 1024 1024" className="nijjar-legacy-icon">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
            <div className="nijjar-legacy-links-container">
              <a href="#articles" className="nijjar-legacy-link anchor">
                Articles
              </a>
              <a href="#tenant" className="nijjar-legacy-link1 anchor">
                <span>Tenant Stories</span>
                <br></br>
              </a>
            </div>
          </div>
          <div className="nijjar-legacy-right-side">
            <a href="#legacy" className="nijjar-legacy-cta-btn anchor button">
              <span>
                <span className="nijjar-legacy-text03">Learn More</span>
                <br className="nijjar-legacy-text04"></br>
              </span>
            </a>
          </div>
          <div data-role="MobileMenu" className="nijjar-legacy-mobile-menu">
            <div className="nijjar-legacy-container1">
              <div
                data-role="CloseMobileMenu"
                className="nijjar-legacy-close-menu"
              >
                <svg viewBox="0 0 1024 1024" className="nijjar-legacy-icon2">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className="nijjar-legacy-links-container1">
              <a href="#articles" className="nijjar-legacy-link2">
                Articles
              </a>
              <a href="#inspiration" className="nijjar-legacy-link3 anchor">
                Legacy
              </a>
              <a href="#process" className="nijjar-legacy-link4 anchor">
                Tenants
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="nijjar-legacy-hero">
        <div className="nijjar-legacy-content-container">
          <div className="nijjar-legacy-hero-text">
            <h1 className="nijjar-legacy-heading">
              <span>Over 40 years of unrivaled accomplishments</span>
              <br></br>
            </h1>
            <span className="nijjar-legacy-text07">
              <span>
                The Nijjar Family&apos;s $1.5 Billion dollar real estate empire
                services countless families with rental properties across Los
                Angeles County, Riverside County, and other parts of California,
                Texas, and Nevada.
              </span>
              <br></br>
              <br></br>
              <span>
                One of their many companies, Regency Property Management prides
                itself on not responding to tenant repair requests and when they
                finally do, completing repairs in such a substandard manner that
                they have to be immediately re-repaired.
              </span>
              <br></br>
              <br></br>
              <span className="nijjar-legacy-text14">
                And that&apos;s not even getting to the lakes of raw sewage
                under the buildings.
              </span>
              <br></br>
            </span>
            <a href="#legacy" className="nijjar-legacy-cta-btn1 anchor button">
              <span>
                <span>Learn More about Mike and Patty Nijjar</span>
                <br></br>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div id="legacy" className="nijjar-legacy-legacy">
        <div className="nijjar-legacy-container2">
          <h2 className="nijjar-legacy-text19 section-Heading">
            <span>A Legacy of Care</span>
            <br></br>
          </h2>
        </div>
        <div className="nijjar-legacy-cards-container">
          <div className="nijjar-legacy-features-card">
            <iframe
              src="https://www.youtube.com/embed/BtrsCx32Z3c"
              className="nijjar-legacy-iframe"
            ></iframe>
          </div>
        </div>
      </div>
      <div id="articles" className="nijjar-legacy-features">
        <div className="nijjar-legacy-container3">
          <h2 className="nijjar-legacy-text22 section-Heading">
            <span>Mike and Patty Nijjar&apos;s Crowning Achievements</span>
            <br></br>
          </h2>
        </div>
        <div className="nijjar-legacy-cards-container1">
          <div className="nijjar-legacy-features-card1">
            <div className="nijjar-legacy-text-container">
              <a
                href="https://projects.laist.com/2020/pama/"
                target="_blank"
                rel="noreferrer noopener"
                className="nijjar-legacy-heading1"
              >
                Deceit, Disrepair and Death Inside a Southern California Real
                Estate Empire
              </a>
              <a
                href="https://projects.laist.com/2020/pama/"
                target="_blank"
                rel="noreferrer noopener"
                className="nijjar-legacy-link5"
              >
                <img
                  alt="image"
                  src="/playground_assets/kpcc-article-thumb-400w.jpg"
                  className="nijjar-legacy-image"
                />
              </a>
              <span className="nijjar-legacy-text25">
                <span className="nijjar-legacy-text26">Laist</span>
                <br></br>
                <span>By Aaron Mendelson</span>
                <br></br>
                <span>February. 12, 2020</span>
              </span>
              <span className="nijjar-legacy-text31">
                <span>
                  &quot;Nijjar himself lives in a Los Angeles suburb that Forbes
                  Magazine once called “America’s Most Expensive ZIP Code.” His
                  12,000-square-foot hillside mansion, dubbed “Villa
                  Bellefontaine,” sits in a gated community and boasts six
                  bedrooms, nine bathrooms, a waterfall, a tennis court, a
                  reflecting pool, a screening room and a vineyard
                </span>
                <br></br>
                <br></br>
                <span>
                  Many of Nijjar’s tenants live in starkly different conditions,
                  fighting off roaches, rats, bedbugs, bees, maggots and mold,
                  all while struggling to get even minor issues fixed. At many
                  of these rentals, low-income residents feel stuck in
                  unsanitary, dangerous housing..&quot;
                </span>
              </span>
              <a
                href="https://projects.laist.com/2020/pama/"
                target="_blank"
                rel="noreferrer noopener"
                className="nijjar-legacy-cta-btn2 anchor button"
              >
                <span>
                  <span>Read More</span>
                  <br></br>
                </span>
              </a>
            </div>
          </div>
          <div className="nijjar-legacy-features-card2">
            <div className="nijjar-legacy-text-container1">
              <a
                href="https://www.latimes.com/homeless-housing/story/2022-04-21/mold-broken-pipes-health-problems-apartment-complex"
                target="_blank"
                rel="noreferrer noopener"
                className="nijjar-legacy-heading2"
              >
                Mold and sewage plague South L.A. apartments even after
                inspections
              </a>
              <a
                href="https://www.latimes.com/homeless-housing/story/2022-04-21/mold-broken-pipes-health-problems-apartment-complex"
                target="_blank"
                rel="noreferrer noopener"
                className="nijjar-legacy-link6"
              >
                <img
                  alt="image"
                  src="/playground_assets/sabrina-latimes-small-300h.png"
                  className="nijjar-legacy-image1"
                />
              </a>
              <span className="nijjar-legacy-text39">
                <span className="nijjar-legacy-text40">LA Times</span>
                <br className="nijjar-legacy-text41"></br>
                <span>By Liam Dillon</span>
                <br></br>
                <span>April 21, 2022</span>
              </span>
              <span className="nijjar-legacy-text45">
                <span>
                  Sabrina Dolan is convinced that her apartment is poisoning
                  her.
                </span>
                <br className="nijjar-legacy-text47"></br>
                <br className="nijjar-legacy-text48"></br>
                <span>
                  Black, mold-like spots dot the windowsill in her living room.
                  They appear on her bedroom windows along with signs of
                  termites. The spots also cover a corner of her bathroom, and
                  no amount of scrubbing can make them go away.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Recently, she’s been coughing up chunks of thick, dark mucus.
                </span>
                <br className="nijjar-legacy-text51"></br>
                <br className="nijjar-legacy-text52"></br>
                <span>
                  And no matter how many times she says she complains to her
                  landlord at the South Los Angeles apartment she shares with
                  her fiancé, nothing ever gets repaired.
                </span>
              </span>
              <a
                href="https://www.latimes.com/homeless-housing/story/2022-04-21/mold-broken-pipes-health-problems-apartment-complex"
                target="_blank"
                rel="noreferrer noopener"
                className="nijjar-legacy-cta-btn3 button anchor"
              >
                <span>
                  <span>Read More</span>
                  <br></br>
                </span>
              </a>
            </div>
          </div>
          <div className="nijjar-legacy-features-card3">
            <div className="nijjar-legacy-text-container2">
              <span className="nijjar-legacy-heading3 card-Heading">
                <span>
                  KPCC Feature radio documentary
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
              </span>
              <div className="nijjar-legacy-html-node">
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      '<iframe src="https://omny.fm/shows/news-reports/stuck-deceit-disrepair-death/embed" width="100%" height="180"\r\n    allow="autoplay; clipboard-write" frameborder="0"\r\n    title="Stuck: Deceit, Disrepair and Death Inside a California Rental Empire"></iframe>',
                  }}
                />
              </div>
            </div>
          </div>
          <div className="nijjar-legacy-features-card4">
            <div className="nijjar-legacy-text-container3">
              <span className="nijjar-legacy-heading4 card-Heading">
                <span>
                  Gimme Shelter podcast Episode on Chesapeake Apartments, Los
                  Angeles
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
              </span>
              <div className="nijjar-legacy-code-embed">
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay"\r\n    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1261648459&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>\r\n<div\r\n    style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;">\r\n    <a href="https://soundcloud.com/matt-levin-4" title="Gimme Shelter: The California Housing Crisis Pod"\r\n        target="_blank" style="color: #cccccc; text-decoration: none;">Gimme Shelter: The California Housing Crisis\r\n        Pod</a> · <a\r\n        href="https://soundcloud.com/matt-levin-4/gimme-shelter-deplorable-conditions-and-poor-oversight-in-los-angeles-apartment-complex"\r\n        title="Gimme Shelter: Deplorable conditions and poor oversight in Los Angeles apartment complex" target="_blank"\r\n        style="color: #cccccc; text-decoration: none;">Gimme Shelter: Deplorable conditions and poor oversight in Los\r\n        Angeles apartment complex</a></div>',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="tenant" className="nijjar-legacy-tenant-stories">
        <div className="nijjar-legacy-heading-container">
          <h1 className="nijjar-legacy-text61 section-Heading">
            <span>Tenant Stories</span>
            <br></br>
          </h1>
          <span className="nijjar-legacy-text64">
            <span>
              Learn more about PAMA Management and the Nijjar family from the
              tenants they serve.
            </span>
            <br></br>
          </span>
        </div>
        <div className="nijjar-legacy-cards-container2">
          <div className="nijjar-legacy-service-card">
            <h3 className="nijjar-legacy-text67 card-Heading">Namuri</h3>
            <iframe
              src="https://www.youtube.com/embed/XultgbRN1RY"
              className="nijjar-legacy-iframe1"
            ></iframe>
          </div>
          <div className="nijjar-legacy-service-card1">
            <h3 className="nijjar-legacy-text68 card-Heading">Zerita</h3>
            <iframe
              src="https://www.youtube.com/embed/CTmjcz8BIsc"
              className="nijjar-legacy-iframe2"
            ></iframe>
          </div>
          <div className="nijjar-legacy-service-card2">
            <h3 className="nijjar-legacy-text69 card-Heading">Ronald</h3>
            <iframe
              src="https://www.youtube.com/embed/pnqbrPp_hjU"
              className="nijjar-legacy-iframe3"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="nijjar-legacy-section-separator"></div>
    </div>
  )
}

export default NijjarLegacy
